<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>{{ this.routeParam.nb_name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="6">
                <v-toolbar dark color="panel1"><v-toolbar-title>Info</v-toolbar-title>
                    <v-spacer></v-spacer>                    
                <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
                <v-btn icon dark @click="send"><v-icon>mdi-send</v-icon></v-btn>
                <v-btn icon dark @click="viewSendList"><v-icon>mdi-view-list-outline</v-icon></v-btn>
            </v-toolbar>             
                <v-card class="mt-5">
                <v-form ref="mainForm">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="form.notification_broadcast_id" disabled label="Rec Id" outlined></v-text-field>                                                                
                                <v-text-field v-model="form.ns_name" label="Sender Setting" :rules="inputRules" outlined readonly></v-text-field>
                                <v-text-field v-model="form.nt_name" label="Template Name" :rules="inputRules" outlined readonly></v-text-field>
                                <v-text-field v-model="form.nc_name" label="Category" :rules="inputRules" outlined readonly></v-text-field>                                
                                <v-text-field v-model="form.nb_code" label="Code" :rules="inputRules" outlined readonly></v-text-field>
                                <v-text-field v-model="form.nb_name" label="Name" :rules="inputRules" outlined readonly></v-text-field>
                                <v-text-field v-model="form.title" label="Title" :rules="inputRules" outlined readonly></v-text-field>
                                <v-textarea v-model="form.message" label="Message" :rules="inputRules" outlined readonly></v-textarea>
                                <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            outlined
                                            :value="form.start_date"
                                            label="Start Date"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :rules="inputRules"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.start_date" @change="dpicker.menu1 = false"></v-date-picker>
                                </v-menu>
                                <v-menu v-model="dpicker.menu2" :close-on-content-click="false" max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            outlined
                                            :value="form.end_date"
                                            label="End Date"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :rules="inputRules"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.end_date" @change="dpicker.menu2 = false"></v-date-picker>
                                </v-menu>
                                <v-text-field v-model="form.url_icon" label="URL Icon" outlined ></v-text-field>
                                <v-text-field v-model="form.url_website" label="URL Website" outlined ></v-text-field>
                                <v-text-field v-model="form.expiry_days" label="Expired in Days" outlined ></v-text-field>
                                <v-select
                                    v-model = "form.status"
                                    :items="status_list"
                                    item-text="description"
                                    item-value="code"
                                    label="Status"
                                    standard
                                    :rules="inputRules"
                                    outlined
                                ></v-select>
                                
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>

                </v-card>

            </v-col>
            <!--image-->
            <v-col cols="12" md="6">
                <v-toolbar dark color="panelSysInfo"><v-toolbar-title>System Info</v-toolbar-title>
                </v-toolbar>             
                <v-card class="mt-5">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-for="info in systemInfo" :key="info.alias" :value="form[info.name]" disabled :label="info.alias" outlined></v-text-field>
                            </v-col>
                        </v-row>

                    </v-container>

                </v-card>

                
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import ApNotificationService from "@/services/ApNotificationService"

export default {
  components:{DlgMessage},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Notification',disabled: false,href:'/notification/',},
              {text:'Broadcast',disabled: false,href:'/Notification/categories',},
              {text:'Details',disabled: false,href:'#',},
              ],
            status_list:[],
            routeParam:[],
            form:{login_id:null,notification_broadcast_id:0},
            systemInfo:[
                {"name":"created_date","alias":"Created Date"},
                {"name":"created_by","alias":"Created By"},
                {"name":"created_at","alias":"Created At"},
                {"name":"updated_date","alias":"Updated Date"},
                {"name":"updated_by","alias":"Updated By"},
                {"name":"updated_at","alias":"Updated At"},
            ],

            logo:'',

            inputRules: [
             v => !!v || 'This field is required'
            ],

            cpFiles:{
                file_type:'image',
                route_type:'merchants',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},

    }
  },
  async created() {
    this.routeParam['notification_broadcast_id'] = this.$route.params.notification_broadcast_id;
    this.routeParam['nb_name'] = atob(this.$route.params.enc_nb_name);
    this.viewDetail();

  },
  
  methods:{

    async viewDetail(){
        this.form.notification_broadcast_id = this.routeParam.notification_broadcast_id
        try{
            ApNotificationService.view_notification_broadcast(this.form).then((res) => {
                this.form = res.data.data;
                for (const [key, value] of Object.entries(this.form)) {
                    if(value == null){
                        this.form[key] = "";
                    }
                }                
                //console.log(res.data);
                this.status_list = res.data.statusList; 
               
            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','View Notification Broadcast',e.response.data.error_message);
            })
            ;
        } catch(e) {
            //console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    async save(){
        if(this.$refs.mainForm.validate()) {
            try{
                ApNotificationService.update_notification_broadcast(this.form).then(() => {
                    this.$refs.dlgMsg.showDlgBox(true,'success','Update Notification Broadcast','Success.');
                }).catch((e)=>{
                    //console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Update Notification Broadcast',e.response.data.error_message);
                })
                ;
            } catch(e) {
                //console.log(e);
                //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
            }
        }
    },
    async send(){
        if(this.$refs.mainForm.validate()) {
            try{
                ApNotificationService.send_notification_broadcast(this.form).then(() => {
                    this.$refs.dlgMsg.showDlgBox(true,'success','Broadcast Notification','Send successfully.');
                }).catch((e)=>{
                    //console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Broadcast Notification',e.response.data.error_message);
                })
                ;
            } catch(e) {
                //console.log(e);
                //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
            }
        }

    },
    viewSendList(){
        this.$router.push({ name: 'notification-broadcast-sendlist', 
            params: { 
                notification_broadcast_id: this.form.notification_broadcast_id,
                enc_nb_name: btoa(this.form.nb_name),
            } 
        })

    },    
    dlgClose(){
        this.visible = false;        
    },
  }
}
</script>